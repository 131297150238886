import React from "react";

function Notes() {
  return (
    <div className="note">
      <h1>This is a React App</h1>
      <p>
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ducimus, ipsum
        sed eligendi ex consequatur qui non nostrum magnam nobis illo neque
        nihil aut inventore?
      </p>
    </div>
  );
}

export default Notes;
